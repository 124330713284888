.ant-layout .ant-layout-sider-zero-width-trigger {
  position: fixed;
  top: unset;
  bottom: 0;
  left: 0;
}

.ant-layout-content {
  min-height: calc(100vh - 94px);
  margin: 24px 16px 0;
}

.ant-table-wrapper .ant-table.ant-table-small,
.ant-card-body {
  font-size: 18px;
}

@media (max-width: 480px) {
  /* .ant-layout > .ant-layout-header {
    padding: 0 34px;
  } */
  .s-hide {
    display: none;
  }
  .ant-layout-content {
    min-height: calc(100vh - 86px);
    margin: 16px 8px 0;
  }
}
