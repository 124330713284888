@media (max-width: 575px) {
  .edit.ant-form > .ant-form-item {
    margin-bottom: 0px;
    width: 50%;
    display: inline-block;
  }
  .edit.ant-form > .ant-form-item:nth-child(2) {
    padding-left: 8px;
  }
}

@media (min-width: 1200px) {
  .flex {
    display: flex;
  }
}

.rowData {
  display: flex;
}

.rowData > div {
  width: 100%;
}

.rowData > div + div {
  margin-left: 10px;
}
